import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { 
  Text, View, Image, Dimensions, Platform, 
  TouchableOpacity, ScrollView } from 'react-native'; 
import styles from './styles';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,

  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveScreenFontSize
} from "react-native-responsive-dimensions";

import * as Linking from 'expo-linking';
//const { width, height } = Dimensions.get('window');  

// const width = 2701;
// const height = 1336;

const height = responsiveScreenHeight(100);
const width = responsiveScreenWidth(100);

import {en, ru, sp} from './Translate';      
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

i18n.fallbacks = true;
i18n.translations = {en, ru, sp}; 
i18n.locale = Localization.locale;

const wPhone = width > 900 ? height*0.55*0.498 : width*0.55*0.498;

export default class MainScr extends React.Component {

  state = {
    selected: null
  };

componentDidMount(){

  console.log('width', width);
console.log('height', height);
}
  async scrollToItem(itemIndex, activeCategor ) {

 
  this.setState({selected: activeCategor}, () => this._scrollView.scrollTo({y: itemIndex * (height*0.9 + 0)})); 
 
  //this.updateMe();
       };

       async scrollToItemMobil(itemIndex, activeCategor ) {

 
        this.setState({selected: activeCategor}, () => this._scrollView.scrollTo({y: itemIndex * (width + 0)})); 
       
        //this.updateMe();
             };

  render() {


if(width > height && width > 900){

    return (
      <View style={styles.container}>
  
         {/* <ScrollView
    horizontal={true}
    > */}
  
  <View style={{width, height: height*0.15, flexDirection: 'row', marginBottom: 80,
    alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: width*0.05,  backgroundColor: '#EFE7E7', }}>
    <Text style = {styles.white150w700Left}>Angel Engineering, LLC</Text>
  
    <View style={{width: width*0.385, height: height*0.15, flexDirection: 'row', paddingHorizontal: 150,
    alignItems: 'center', justifyContent: 'space-around',
     }}>
        <TouchableOpacity  
                onPress={() => { 
                  this.scrollToItem(1, 'team');
                //  this.props.navigation.navigate('vizeart');
                  
                 // this.setState({selected: 'team'});
                  }}
        >
          {
          this.state.selected == 'team' ?
    <Text style = {styles.blackTitleLine}>{ i18n.t('TeamMn') }</Text>
    :
    <Text style = {styles.blackTitle}>{ i18n.t('TeamMn') }</Text>
          }
    </TouchableOpacity>  
  
    <TouchableOpacity  
                onPress={() => { 
                  if(i18n.t('language') == 'ru'){
                  this.scrollToItem(3, 'apps')
                  } else {
                    this.scrollToItem(2, 'apps')
                  }
                //  this.setState({selected: 'apps'});
                  }}
        >
          {
          this.state.selected == 'apps' ?
    <Text style = {styles.blackTitleLine}>{ i18n.t('PrtMn') }</Text>
    :
    <Text style = {styles.blackTitle}>{ i18n.t('PrtMn') }</Text>
          }
    </TouchableOpacity>  
  
    <TouchableOpacity  
                onPress={() => { 
                  if(i18n.t('language') == 'ru'){
                    this.scrollToItem(4, 'apps')
                    } else {
                      this.scrollToItem(3, 'apps')
                    }
               
                 // this.setState({selected: 'contacts'});
                  }}
        >
          {
          this.state.selected == 'contacts' ?
    <Text style = {styles.blackTitleLine}>{ i18n.t('CntsMn') }</Text>
    :
    <Text style = {styles.blackTitle}>{ i18n.t('CntsMn') }</Text>
          }
    </TouchableOpacity>  
  
  
    </View>
  
    <Image
             source={require("./assets/Logo.png")}    
            style={{ position: 'absolute',  right: width*0.05,   height: height*0.2, width: height*0.2 }} 
          />
    </View>
  
  
        <ScrollView
         ref={view => this._scrollView = view}
        showsVerticalScrollIndicator = {false}>
  
  
  <View style={{width: width*0.9, height: height*0.9,
  flexDirection: 'row' }}>
  
  <View style={{width: width*0.45, height: height*0.9, alignItems: 'flex-start', justifyContent: 'center', paddingLeft: 100, backgroundColor: 'white'}}>
  
  
  <Text style = {styles.black40w400}>{ i18n.t('DescMn') }</Text>
    
  
    <View style = {{paddingTop: 15, paddingBottom: 15, width: width*0.3}}>
  <Text style = {styles.red80w700}
  >{ i18n.t('DesMn2') }</Text>
    <Text style = {styles.red80w700}>{ i18n.t('DesMn3') }</Text>
    </View>
  
    <Text style = {styles.black40w400}>{ i18n.t('DesMn4') }</Text>
  
    <View style = {{paddingTop: 80, paddingBottom: 40, width: width*0.3}}>
    <Text style = {styles.black20w400}>{ i18n.t('DesMn5') }</Text>
    </View>
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
  
  </View>
  
  
  
  <View style={{width: width*0.4, height: height*0.9, backgroundColor: '#FF5A00'}}>
  
  <Image
             source={require("./assets/Main1.png")}    
            style={{ position: 'absolute', top: height*0.15, left: -height*0.1,  height: height*0.6, width: height*0.6*0.498 }} 
          />
  
  <Image
             source={require("./assets/Main3.png")}    
            style={{ position: 'absolute', top: -height*0.2, left: wPhone ,  height: height*0.6, width: height*0.6*0.498 }} 
          />
  
  <Image
             source={require("./assets/Main2.png")}    
            style={{ position: 'absolute', top: height*0.4 + 20, left: wPhone ,  height: height*0.6, width: height*0.6*0.498 }} 
          />
  
  </View>
  
  <View style={{width: width*0.05, height: height*0.9, backgroundColor: '#ffffff'}}></View>
  
  </View>
  
  
  <View style={{width: width*0.9,
    marginBottom: 40,
    height: height*0.95, flexDirection: 'row', alignContent: 'center',  backgroundColor: 'transparent'}}>
  

  
  {/* <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: height*0.05, }}>
    <Image
             source={ i18n.t('VisCnst') == 'ru' ?
              require("./assets/1.jpg") :
              i18n.t('VisCnst') == 'sp' ?
              require("./assets/1sp.jpg") :
              require("./assets/1en.jpg")
            }    
            style={{ 
            width: width*0.15, height: width*0.3, borderRadius: 20, }} 
          />

  </View> */}

<View style = {{width: width*0.3, 
  alignItems: 'center', justifyContent: 'flex-start', paddingTop: 50}}>

<ScrollView >
<Text style = {styles.black11w400}>{i18n.t('MainTxt1')}</Text>
<Text style = {styles.black11w400}>{i18n.t('MainTxt2')}</Text>
<Text style = {styles.black11w400}>{i18n.t('MainTxt3')}</Text>
<Text style = {styles.black11w400}>{i18n.t('MainTxt4')}</Text>
</ScrollView>
    </View>
  
  {/* <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: height*0.2}}>
    <Image
                source={ i18n.t('VisCnst') == 'ru' ?
                require("./assets/2.jpg") :
                i18n.t('VisCnst') == 'sp' ?
                require("./assets/2sp.jpg") :
                require("./assets/2en.jpg")
              }    
            style={{ 
            width: width*0.15, height: width*0.3, borderRadius: 20, }} 
          />
  </View> */}
  
  <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: height*0.35}}>
    <Image
                  source={ i18n.t('VisCnst') == 'ru' ?
                  require("./assets/2.jpg") :
                  i18n.t('VisCnst') == 'sp' ?
                  require("./assets/2sp.jpg") :
                  require("./assets/2en.jpg")
}   
            style={{ 
            width: width*0.15, height: width*0.3, borderRadius: 20, }} 
          />
  </View>


  <View >
<View style = {{paddingTop: height*0.2,  width: width*0.3}}> 
<Text style = {styles.black80w700}>Apps Maker</Text>
    </View>
  
    <View style = {{paddingTop: 10, paddingBottom: 40, width: width*0.3}}>
    
    <Text style = {styles.black40w400}>{ i18n.t('VisCnst') }</Text>
    </View> 

    <View >
  <TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://apps.apple.com/app/apps-maker/id6448683619`);
            }}
        >
    <Image
             source={require("./assets/appStore.png")}    
             style={{width: width*0.24, height: width*0.0744,  }} 
          />
</TouchableOpacity>

<TouchableOpacity  
style = {{width: width*0.24, height: width*0.0744, marginTop: 15}}
            onPress={ () => {
              Linking.openURL(`https://play.google.com/store/apps/details?id=com.borfor.myapps`);
              }}
        >
    <Image
             source={require("./assets/googlePlay.png")}    
             style={{width: width*0.24, height: width*0.0744,  }} 
          />
</TouchableOpacity>
</View> 
  
    {/* <View style={{marginTop: 40, width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
     */}
    </View>
  
  </View> 
  
  {i18n.t('language') == 'ru' ? 
  <View style={{width: width*0.9, height: width*0.55, 
  marginBottom: 40,
  justifyContent: 'center'  }} >

  <TouchableOpacity  
style = {{paddingTop: 10}}
            onPress={ () => {
              Linking.openURL(`https://www.youtube.com/@AppsMaker_info`);
              }}
        >
    <Image
             source={require("./assets/YouTubeScr.jpg")}    
             style={{width: width*0.9, height: width*0.55,  }} 
          />
</TouchableOpacity>

  </View>
  :
  null
            }
  
  
  <View style={{width: width*0.9, height: height*0.9,  backgroundColor: '#FF5A00'}}>
  
  <View style = {{ position: 'absolute', top: 20, right: 30 , }}>
  <View style = {{paddingTop: 40,  width: width*0.15}}> 
  <Text style = {styles.white80w700}>{ i18n.t('Prjs1') }</Text>
    <Text style = {styles.white80w700}>{ i18n.t('Prjs2') }</Text>
    </View>
  
    <View style = {{paddingTop: 40, paddingBottom: 40, width: width*0.25}}>
    
    <Text style = {styles.white20w400}>{ i18n.t('OthToo') }</Text>
    <Text style = {styles.white20w400}>{ i18n.t('AnyFoo') }</Text>
    </View> 
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#ffffff'}}></View>
    </View>
  
  
    <View style = {[{position: 'absolute', top: height*0.5, left: width*0.42, }, {
    transform: [{ rotate: "-40deg" }]
  }]} >
  
    <Image
         //    source={require("./assets/MyPlace.jpg")}    
             source={require("./assets/Miraland.jpg")}  
            style={{ 
            width: width*0.4, height: width*0.217, borderRadius: 20, }} 
          />
  </View> 
  
  <View style = {[{position: 'absolute', top: height*0.45, left: width*0.27, }, {
    transform: [{ rotate: "-30deg" }]
  }]} >
    <Image
             source={require("./assets/Stroy.jpg")}    
            style={{ 
            width: width*0.4, height: width*0.217, borderRadius: 20, }} 
          />
  </View>
  
  <View style = {[{position: 'absolute', top: height*0.3, left: width*0.15, }, {
    transform: [{ rotate: "-20deg" }]
  }]} >
    <Image
             source={require("./assets/Titrovoice.jpg")}    
            style={{ 
            width: width*0.4, height: width*0.235, borderRadius: 20, }} 
          />
  </View>
  
  <View style = {[{position: 'absolute', top: height*0.1, left: width*0.05, }, {
    transform: [{ rotate: "-10deg" }]
  }]} >
    <Image
             source={require("./assets/GTSdriverSum.jpg")}    
            style={{ 
            width: width*0.4, height: width*0.217, borderRadius: 20, }} 
          />
  </View>
  
  
    </View>
  
    <View style={{width: width*0.9, height: height*0.9, flexDirection: 'row',  backgroundColor: 'transparent'}}>
  
  
  <View style = {{paddingTop: 80,  width: width*0.3, paddingLeft: 100}}> 
  <Text style = {styles.black80w700}>{ i18n.t('Wrus1') }</Text>
  <Text style = {styles.black80w700}>{ i18n.t('Wrus2') }</Text>
  
    <View style = {{paddingTop: 40, paddingBottom: 40, width: width*0.3}}>
    
    <Text style = {styles.black40w400}>{ i18n.t('WeGl1') }</Text>
    <Text style = {styles.black40w400}>{ i18n.t('WeGl2') }</Text>
    </View> 
    
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
    
    </View>
  
    <View style = {{
      width: width*0.3,  
      height: width*0.4,
      alignItems: 'center',
     // backgroundColor: 'red',
      justifyContent: 'center', paddingTop: height*0.05}}>
    {/* <Image
             source={require("./assets/contacts.png")}    
            style={{ 
            width: width*0.38, height: width*0.4, borderRadius: 20, }} 
          /> */}

<TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`mailto:info@ae-com.site`);
            }}
        >
    <View 
    // style = {{width: width*0.3,  
    //   alignItems: 'center', 
    //   justifyContent: 'flex-start', 
    //   paddingTop: height*0.1}}
      >
    <Text style = {styles.red80w700}
>info@ae-com.site</Text>
  
  </View>
  </TouchableOpacity>
  
  </View>
  
    </View>
  
  
  
    <View style={{width: width*0.9, height: height*0.15, 
    alignItems: 'center', justifyContent: 'center',  backgroundColor: '#FF5A00'}}>
  
  <Text style = {styles.white150w700}>{ i18n.t('ThVis') }</Text>
  
    </View>
  
    <View style={{width: width*0.9, height: height*0.1, 
    alignItems: 'center', justifyContent: 'center',  backgroundColor: 'transparent'}}>
    <Text style = {styles.black20w400}>© 2023 Angel Engineering</Text>
    </View>
  
  </ScrollView> 
      </View>
    );

 
}


else{
  return (
    <View style={styles.containerMob}>

       {/* <ScrollView
  horizontal={true}
  > */}

<View style={{width, height: width*0.18, flexDirection: 'row', 
  alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: width*0.05,  backgroundColor: '#EFE7E7', 
  marginBottom: 0,
  }}>

    <View style={{width: width*0.45, height: width*0.18, alignItems: 'flex-start', justifyContent: 'center', }}>
    <Text style = {styles.white150w700Left}>Angel Engineering</Text>
  </View>
{
  width < 900 ?
  null
  :
  <View style={{width: width*0.385, height: width*0.18, flexDirection: 'row', paddingHorizontal: width*0.05,
  alignItems: 'center', justifyContent: 'space-around', 
   }}>
      <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(1, 'team')
               // this.setState({selected: 'team'});
                }}
      >
        {
        this.state.selected == 'team' ?
        <Text style = {styles.blackTitleLine}>{ i18n.t('TeamMn') }</Text>
        :
        <Text style = {styles.blackTitle}>{ i18n.t('TeamMn') }</Text>
        }
  </TouchableOpacity>  

  <TouchableOpacity  
              onPress={() => { 
                if(i18n.t('language') == 'ru'){
                  this.scrollToItem(3, 'apps')
                  } else {

                    this.scrollToItem(2, 'apps')
                  }
                }}
      >
        {
        this.state.selected == 'apps' ?
        <Text style = {styles.blackTitleLine}>{ i18n.t('PrtMn') }</Text>
        :
        <Text style = {styles.blackTitle}>{ i18n.t('PrtMn') }</Text>
        }
  </TouchableOpacity>  

  <TouchableOpacity  
              onPress={() => { 
                if(i18n.t('language') == 'ru'){
                  this.scrollToItem(4, 'apps')
                  } else {
                    this.scrollToItem(3, 'apps')
                  }
                }}
      >
        {
        this.state.selected == 'contacts' ?
        <Text style = {styles.blackTitleLine}>{ i18n.t('CntsMn') }</Text>
        :
        <Text style = {styles.blackTitle}>{ i18n.t('CntsMn') }</Text>
        }
  </TouchableOpacity>  


  </View>
  }

  <Image
           source={require("./assets/Logo.png")}    
          style={{ position: 'absolute',  right: width*0.05,   height: width*0.2, width: width*0.2 }} 
        />


  </View>


{
  width >= 900 ?
  null
  :
  <View style={{width: width*0.7, height: width*0.1, flexDirection: 'row', paddingHorizontal: width*0.05, 
  alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'space-around', marginBottom: 10,
   }}>
      <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(1, 'team')
               // this.setState({selected: 'team'});
                }}
      >
        {
        this.state.selected == 'team' ?
        <Text style = {styles.blackTitleLine}>{ i18n.t('TeamMn') }</Text>
        :
        <Text style = {styles.blackTitle}>{ i18n.t('TeamMn') }</Text>
        }
  </TouchableOpacity>  

  <TouchableOpacity  
              onPress={() => { 
                if(i18n.t('language') == 'ru'){
                  this.scrollToItem(3, 'apps')
                  } else {
                    this.scrollToItem(2, 'apps')
                  }
                }}
      >
        {
        this.state.selected == 'apps' ?
        <Text style = {styles.blackTitleLine}>{ i18n.t('PrtMn') }</Text>
        :
        <Text style = {styles.blackTitle}>{ i18n.t('PrtMn') }</Text>
        }
  </TouchableOpacity>  

  <TouchableOpacity  
              onPress={() => { 
                if(i18n.t('language') == 'ru'){
                  this.scrollToItem(4, 'apps')
                  } else {
                    this.scrollToItem(3, 'apps')
                  }
                }}
      >
        {
        this.state.selected == 'contacts' ?
        <Text style = {styles.blackTitleLine}>{ i18n.t('CntsMn') }</Text>
        :
        <Text style = {styles.blackTitle}>{ i18n.t('CntsMn') }</Text>
        }
  </TouchableOpacity>  


  </View>
  }


      <ScrollView
       ref={view => this._scrollView = view}
      showsVerticalScrollIndicator = {false}>


<View style={{width: width*0.9, height: width*1,
flexDirection: 'row' }}>

<View style={{width:  width*0.45 , height: width*1,  alignItems: 'flex-start', 
justifyContent: 'center', paddingLeft: 10, }}>

<View style = {{width: width*0.4}}>
<Text style = {styles.black40w400}>{ i18n.t('DescMn') }</Text>
  </View>

  <View style = {{paddingTop: 15, paddingBottom: 15, width: width*0.4}}>
<Text style = {styles.red80w700}>{ i18n.t('DesMn2') }</Text>
  <Text style = {styles.red80w700}>{ i18n.t('DesMn3') }</Text>
  </View>

  <View style = {{width: width*0.4}}>
  <Text style = {styles.black40w400}>{ i18n.t('DesMn4') }</Text>
  </View>

  <View style = {{paddingTop: width > 900 ? 80 : 40, paddingBottom: width > 900 ? 40 : 20,  width: width*0.3}}>
  <Text style = {styles.black20w400}>{ i18n.t('DesMn5') }</Text>
  </View> 

  <View style={{width: width*0.1, height: width*0.005, borderRadius: width*0.005/2,  backgroundColor: '#292929'}}></View> 

</View>



<View style={{width: width > 900 ? width*0.4 : width*0.5, height: width*1, backgroundColor: '#FF5A00'}}>

<Image
           source={require("./assets/Main1.png")}    
          style={{ position: 'absolute', top: width*0.2, left: -width*0.07,  height: width*0.6, width: width*0.6*0.498 }} 
        />

<Image
           source={require("./assets/Main3.png")}    
          style={{ position: 'absolute', top:  width > 900 ? -width*0.2 :  -width*0.1, left: wPhone ,   height: width*0.6, width: width*0.6*0.498 }} 
        />

<Image
           source={require("./assets/Main2.png")}    
          style={{ position: 'absolute', top: width > 900 ? width*0.4 + 20 : width*0.5 + 20, left: wPhone ,   height: width*0.6, width: width*0.6*0.498}} 
        />

</View>

{width < 900 ?
null
:
<View style={{width: width*0.05, height: height*0.9, backgroundColor: '#ffffff'}}></View>
  }
</View>


<View style={{width: width*0.9, height: width -150, marginBottom: 30,
  flexDirection: 'row', alignContent: 'center',  backgroundColor: 'transparent'}}>


{/* <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: width*0.05, }}>
  <Image
                  source={ i18n.t('VisCnst') == 'ru' ?
                  require("./assets/1.jpg") :
                  i18n.t('VisCnst') == 'sp' ?
                  require("./assets/1sp.jpg") :
                  require("./assets/1en.jpg")
                }   
          style={{ 
          width: width*0.2, height: width*0.4, borderRadius: 20, }} 
        />

</View> */}

<View style = {{width: width*0.3, 
  //alignItems: 'center',
   justifyContent: 'flex-start', paddingTop: 40}}>

<ScrollView >
<Text style = {styles.black11w400}>{i18n.t('MainTxt1')}</Text>
<Text style = {styles.black11w400}>{i18n.t('MainTxt2')}</Text>
<Text style = {styles.black11w400}>{i18n.t('MainTxt3')}</Text>
<Text style = {styles.black11w400}>{i18n.t('MainTxt4')}</Text>
</ScrollView>
    </View>

<View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: width*0.20}}>
  <Image
            source={ i18n.t('VisCnst') == 'ru' ?
            require("./assets/2.jpg") :
            i18n.t('VisCnst') == 'sp' ?
            require("./assets/2sp.jpg") :
            require("./assets/2en.jpg")
          }    
          style={{ 
          width: width*0.2, height: width*0.4, borderRadius: 20, }} 
        />

</View>

{/* <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: width*0.35}}>
  <Image
           source={ i18n.t('VisCnst') == 'ru' ?
           require("./assets/3.jpg") :
           i18n.t('VisCnst') == 'sp' ?
           require("./assets/3sp.jpg") :
           require("./assets/3en.jpg")
         }     
          style={{ 
          width: width*0.2, height: width*0.4, borderRadius: 20, }} 
        />

</View> */}


<View >
<View style = {{paddingTop: height*0.1,  width: width*0.3}}> 
<Text style = {styles.black80w700}>Apps Maker</Text>
  </View>

  <View style = {{paddingTop: 10, paddingBottom: width > 900 ? 40 : 20, width: width*0.3}}>
  
  <Text style = {styles.black40w400}>{ i18n.t('VisCnst') }</Text>
  </View> 



  <View >
  <TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://apps.apple.com/app/apps-maker/id6448683619`);
            }}
        >
    <Image
             source={require("./assets/appStore.png")}    
             style={{width: width*0.24, height: width*0.0744,  }} 
          />
</TouchableOpacity>

<TouchableOpacity  
style = {{width: width*0.24, height: width*0.0744, marginTop: 10}}
            onPress={ () => {
              Linking.openURL(`https://play.google.com/store/apps/details?id=com.borfor.myapps`);
              }}
        >
    <Image
             source={require("./assets/googlePlay.png")}    
             style={{width: width*0.24, height: width*0.0744,  }} 
          />
</TouchableOpacity>
</View> 

{/* 
  <View style={{marginTop: width*0.0372 + 40, width: width*0.1, height: width*0.005, 
    borderRadius: width*0.005/2,  backgroundColor: '#292929'}}></View>  */}
  </View>



</View> 

{i18n.t('language') == 'ru' ? 
  <View style={{width: width*0.9, height: width*0.55, 
  marginBottom: 40,
  justifyContent: 'center'  }} >

  <TouchableOpacity  
style = {{paddingTop: 10}}
            onPress={ () => {
              Linking.openURL(`https://www.youtube.com/@AppsMaker_info`);
              }}
        >
    <Image
             source={require("./assets/YouTubeScr.jpg")}    
             style={{width: width*0.9, height: width*0.55,  }} 
          />
</TouchableOpacity>

  </View>
  :
  null
            }

<View style={{width: width*0.9, height: width,  backgroundColor: '#FF5A00'}}>

<View style = {{ position: 'absolute', top: 20, right: 30 , }}>
<View style = {{paddingTop: 20,  width: width*0.3}}> 
<Text style = {styles.white80w700}>{ i18n.t('Prjs1') }</Text>
  <Text style = {styles.white80w700}>{ i18n.t('Prjs2') }</Text>
  </View>

  <View style = {{paddingTop: 15, paddingBottom: width > 900 ? 40 : 20, width: width*0.3}}>
  
  <Text style = {styles.white20w400}>
  { i18n.t('OthToo') }</Text>
  <Text style = {styles.white20w400}>{ i18n.t('AnyFoo') }</Text>
  </View> 

  <View style={{width: width*0.1, height: width*0.005, borderRadius: width*0.005/2,  backgroundColor: '#ffffff'}}></View> 

  </View>


  <View style = {[{position: 'absolute', top: width*0.7, left: width*0.3, }, {
  transform: [{ rotate: "-40deg" }]
}]} >

  <Image
          // source={require("./assets/MyPlace.jpg")} 
           source={require("./assets/Miraland.jpg")}
          style={{ 
          width: width*0.4, height: width*0.217, borderRadius: 10, }} 
        />
</View> 

<View style = {[{position: 'absolute', top: width*0.53, left: width*0.18, }, {
  transform: [{ rotate: "-30deg" }]
}]} >
  <Image
           source={require("./assets/Stroy.jpg")}    
          style={{ 
          width: width*0.4, height: width*0.217, borderRadius: 10, }} 
        />
</View>

<View style = {[{position: 'absolute', top: width*0.3, left: width*0.08, }, {
  transform: [{ rotate: "-20deg" }]
}]} >
  <Image
           source={require("./assets/Titrovoice.jpg")}    
          style={{ 
          width: width*0.4, height: width*0.235, borderRadius: 10, }} 
        />
</View>

<View style = {[{position: 'absolute', top: width*0.1, left: width*0.02, }, {
  transform: [{ rotate: "-10deg" }]
}]} >
  <Image
           source={require("./assets/GTSdriverSum.jpg")}    
          style={{ 
          width: width*0.4, height: width*0.217, borderRadius: 10, }} 
        />
</View>


  </View>

  <View style={{width: width*0.9, height: width, flexDirection: 'row',  backgroundColor: 'transparent'}}>


<View style = {{paddingTop: 40,  width: width*0.4, paddingLeft: width > 900 ? 100 : 10,}}> 
<Text style = {styles.black80w700}>{ i18n.t('Wrus1') }</Text>
<Text style = {styles.black80w700}>{ i18n.t('Wrus2') }</Text>

  <View style = {{paddingTop: 15, paddingBottom: width > 900 ? 40 : 20, width: width*0.3}}>
  
  <Text style = {styles.black40w400}>{ i18n.t('WeGl1') }</Text>
  <Text style = {styles.black40w400}>{ i18n.t('WeGl2') }</Text>
  </View> 
  

  <View style={{width: width*0.1, height: width*0.005, borderRadius: width*0.005/2,  backgroundColor: '#292929'}}></View> 
  
  </View>

  <View style = {{
    width: width*0.3, 
    height: width*0.4,
   alignItems: 'center',
    justifyContent: 'center',
  marginLeft: 10, paddingTop: width*0.05}}>
  {/* <Image
           source={require("./assets/contacts.png")}    
          style={{ 
          width: width*0.38, height: width*0.4, borderRadius: 20, }} 
        /> */}

<TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`mailto:info@ae-com.site`);
            }}
        >
    <View 
    // style = {{width: width*0.3,  
    //   alignItems: 'center', 
    //   justifyContent: 'flex-start', 
    //   paddingTop: height*0.1}}
      >
    <Text style = {styles.red80w700}
>info@ae-com.site</Text>
  
  </View>
  </TouchableOpacity>

</View>

  </View>

  <View style={{width: width*0.9, height: width*0.15, 
  alignItems: 'center', justifyContent: 'center',  backgroundColor: '#FF5A00'}}>

<Text style = {styles.white150w700}>{ i18n.t('ThVis') }</Text>

  </View>

  <View style={{width: width*0.9, height: width*0.1, 
  alignItems: 'center', justifyContent: 'center',  backgroundColor: 'transparent'}}>
  <Text style = {styles.black20w400}>© 2021 Angel Engineering</Text>
  </View>

</ScrollView> 
    </View>
  );
}
}
}

