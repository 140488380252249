import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { 
  Text, View, Image, Dimensions, Platform, 
  TouchableOpacity, ScrollView } from 'react-native';
import styles from './styles';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,

  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveScreenFontSize
} from "react-native-responsive-dimensions";

import * as Linking from 'expo-linking';
//const { width, height } = Dimensions.get('window');  

// const width = 2701;
// const height = 1336;

const height = responsiveScreenHeight(100);
const width = responsiveScreenWidth(100);

import {en, ru, sp} from './Translate';      
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

i18n.fallbacks = true;
i18n.translations = {en, ru, sp}; 
i18n.locale = Localization.locale;

const wPhone = width > 900 ? height*0.55*0.498 : width*0.55*0.498;

export default class DownloadApp extends React.Component {

  state = {
    selected: null
  };

componentDidMount(){

}
  async scrollToItem(itemIndex, activeCategor ) {

 
  this.setState({selected: activeCategor}, () => this._scrollView.scrollTo({y: itemIndex * (height*0.9 + 0)})); 
 
  //this.updateMe();
       };

       async scrollToItemMobil(itemIndex, activeCategor ) {

 
        this.setState({selected: activeCategor}, () => this._scrollView.scrollTo({y: itemIndex * (width + 0)})); 
       
        //this.updateMe();
             };

  render() {


 if(width > height && width > 900){

    return (
      <View style={styles.container}>

  <View style={{width, height: height*0.15, flexDirection: 'row', marginBottom: 80,
    alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: width*0.05,  backgroundColor: '#EFE7E7', }}>
    <Text style = {styles.white150w700Left}>Apps Maker</Text>

    <Image
             source={require("./assets/AmSm.jpg")}    
            style={{ position: 'absolute',  right: width*0.05,  
             height: height*0.2, width: height*0.2,
            borderRadius: width*0.015
            }} 
          />
    </View>
  

  <View style = {{alignSelf: 'center', alignItems: 'center'}} >
<View style = {{alignSelf: 'center', alignItems: 'center',
paddingTop: height*0.2,  width: width*0.3}}> 
<Text style = {styles.black80w700C}>Apps Maker</Text>
    </View>
  
    <View style = {{paddingTop: 10, alignSelf: 'center', alignItems: 'center',
     paddingBottom: 40, width: width*0.3}}>
    
    <Text style = {styles.black40w400C}>{ i18n.t('VisCnst') }</Text>
    </View> 

    <View >
  <TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://apps.apple.com/app/apps-maker/id6448683619`);
            }}
        >
    <Image
             source={require("./assets/appStore.png")}    
             style={{width: width*0.24, height: width*0.0744,  }} 
          />
</TouchableOpacity>

<TouchableOpacity  
style = {{width: width*0.24, height: width*0.0744, marginTop: 15}}
            onPress={ () => {
              Linking.openURL(`https://play.google.com/store/apps/details?id=com.borfor.myapps`);
              }}
        >
    <Image
             source={require("./assets/googlePlay.png")}    
             style={{width: width*0.24, height: width*0.0744,  }} 
          />
</TouchableOpacity>
</View> 
  
    {/* <View style={{marginTop: 40, width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
     */}
    </View>
  
    <View style={{width: width*0.9, height: height*0.1, 
    alignItems: 'center', justifyContent: 'center',  backgroundColor: 'transparent'}}>
    <Text style = {styles.black20w400}>© 2023 Angel Engineering</Text>
    </View>

  </View> 

 
)}


else{
  return (
    <View style={styles.containerMob2}>


<View style={{width, height: height*0.15,
 flexDirection: 'row', marginBottom: 80,
    alignItems: 'center',
     justifyContent: 'flex-start', paddingHorizontal: width*0.05,  backgroundColor: '#EFE7E7', }}>
    <Text style = {styles.white150w700Left}>Apps Maker</Text>

    <Image
             source={require("./assets/AmSm.jpg")}    
            style={{ position: 'absolute',  right: width*0.05,  
             height: height*0.2, width: height*0.2,
            borderRadius: width*0.045
            }} 
          />
    </View>
  

  <View style = {{alignSelf: 'center', alignItems: 'center'}} >
<View style = {{alignSelf: 'center', alignItems: 'center',
paddingTop: height*0.1,  width: width*0.3}}> 
<Text style = {styles.black80w700C}>Apps Maker</Text>
    </View>
  
    <View style = {{paddingTop: 10, alignSelf: 'center', alignItems: 'center',
     paddingBottom: 40, width: width*0.3}}>
    
    <Text style = {styles.black40w400C}>{ i18n.t('VisCnst') }</Text>
    </View> 

    <View >
  <TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://apps.apple.com/app/apps-maker/id6448683619`);
            }}
        >
    <Image
             source={require("./assets/appStore.png")}    
             style={{width: width*0.48, height: width*0.1488,  }} 
          />
</TouchableOpacity>

<TouchableOpacity  
style = {{width: width*0.48, height: width*0.1488, 
     marginTop: 15}}
            onPress={ () => {
              Linking.openURL(`https://play.google.com/store/apps/details?id=com.borfor.myapps`);
              }}
        >
    <Image
             source={require("./assets/googlePlay.png")}    
             style={{ width: width*0.48, height: width*0.1488,   }} 
          />
</TouchableOpacity>
</View> 

    </View>
  
    <View style={{width: width*0.9, height: height*0.1, 
    alignItems: 'center', justifyContent: 'center',  backgroundColor: 'transparent'}}>
    <Text style = {styles.black20w400}>© 2023 Angel Engineering</Text>
    </View>

    </View>
  );
}
}
}

