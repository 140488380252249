import {createStackNavigator} from 'react-navigation-stack';
import {createAppContainer} from 'react-navigation';
import {createBrowserApp} from '@react-navigation/web';

import MainScr from "./MainScr";
import agreementGTS from "./agreementGTS";
import agreementViz from "./agreementViz";
import agrmAppsMaker from "./agrmAppsMaker";
import agrmCookBook from "./agrmCookBook";


import GTS from "./GTS";
import vizeart from "./vizeart";
import DownloadApp from "./DownloadApp";


import {Platform} from "react-native";

const isWeb = Platform.OS === 'web';


const Home = createStackNavigator(
    {
        MainScr: MainScr,
        agreementGTS: agreementGTS,
        agreementViz: agreementViz,
        agrmAppsMaker: agrmAppsMaker,
        agrmCookBook: agrmCookBook,
        GTS: GTS,
        vizeart: vizeart,
        DownloadApp: DownloadApp,
    },   
    {
        headerMode: 'none',
      }, 

    {  
        initialRouteName: "MainScr"   
    } , 
    {
        // navigationOptions: {
        //     headerTintColor: '#fff',
        //     headerStyle: {
        //         backgroundColor: '#000',
        //     },
        // },
    }
);

const container = isWeb ? createBrowserApp(Home): createAppContainer(Home);

export default container;