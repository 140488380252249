import React from 'react';
import {Dimensions, StyleSheet, Text, View} from 'react-native';
import * as Font from 'expo-font';
import Home from './Home';


export default class App extends React.Component { 

  state = {
    isLoading: true,
  };



async componentDidMount() {


  await this.loadFonts();  


  this.setState({isLoading: false}); 
}

async loadFonts() {


await Font.loadAsync({ 
  // Load a font `Roboto` from a static resource
  'Roboto-400': require('./assets/fonts/Roboto-Regular.ttf'), 
  'Roboto-500': require('./assets/fonts/Roboto-Medium.ttf'), 
  'Roboto-700': require('./assets/fonts/Roboto-Bold.ttf'), 

});

}



// async cacheResourcesAsync() {   
// const images = [require("./assets/SplashScreenGTS.png")]; 
// const cacheImages = images.map(image => Asset.fromModule(image).downloadAsync());
// return Promise.all(cacheImages);
// }


render() {


  return (
    <Home/>
    // <View style={styles.outer}>
    //   <Home/>
    // </View>
  );
}
}

