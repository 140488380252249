import { Dimensions, StyleSheet } from 'react-native';
import { Constants } from 'expo';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,

  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveScreenFontSize
} from "react-native-responsive-dimensions";

const { width, height } = Dimensions.get('window');   

const styles = StyleSheet.create({

  
  //used
  containerTab: { 
    flex: 1, 
    flexDirection: 'column',  
    alignItems: 'center', 
    justifyContent: 'center',    
    backgroundColor: 'white', 

    padding: 10, 

  },

  containerKeyb2: {  
    flex: 1, 
    flexDirection: 'column', 
    alignItems: 'center',     
    backgroundColor: 'white' ,    
     

  },
  container: {
    flex: 1,
   // backgroundColor: '#fff',
    backgroundColor: '#F9F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 80,
  //  padding:40
  },

  containerMob: {
    flex: 1,
   // backgroundColor: '#fff',
    backgroundColor: '#F9F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 40,
  //  padding:40
  },

  containerMob2: {
    flex: 1,
   // backgroundColor: '#fff',
    backgroundColor: '#F9F5F5',
    alignItems: 'center',
  //  justifyContent: 'center',
    paddingTop: 80,
  //  padding:40
  },
  
  red80w700: 
  {fontSize: responsiveScreenFontSize(2.5),    
    textAlign: 'left',  
    color: '#FF5A00', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },


  black80w700: 
  {fontSize: responsiveScreenFontSize(2.5),  
    textAlign: 'left',  
    color: '#292929', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },

  black80w700C: 
  {fontSize: responsiveScreenFontSize(2.5),  
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },

  white80w700: 
  {fontSize: responsiveScreenFontSize(2.5),  
    textAlign: 'left',  
    color: '#ffffff', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },

  white150w700: 
  {fontSize: responsiveScreenFontSize(3.1),    
    textAlign: 'center',  
    color: '#ffffff', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },

  white150w700Left: 
  {fontSize: responsiveScreenFontSize(3.1),    
    textAlign: 'left',  
    color: '#ffffff', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },

  black40w400: 
  {fontSize: responsiveScreenFontSize(1.5),   
    textAlign: 'left',  
    color: '#292929', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  black40w400C: 
  {fontSize: responsiveScreenFontSize(1.5),   
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  black20w400: 
  {fontSize: responsiveScreenFontSize(0.9),   
    textAlign: 'left',  
    color: '#292929', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  white20w400: 
  {fontSize: responsiveScreenFontSize(1.5),   
    textAlign: 'left',  
    color: '#ffffff', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  black15w400: 
  {fontSize: 15,   
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  black15w500: 
  {fontSize: responsiveScreenFontSize(0.9),   
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  black11w400: 
  {fontSize: responsiveScreenFontSize(1.1),   
    color: '#292929', 
    fontFamily: 'Roboto-400', 
    fontWeight: '400',
  },

  black15w700: 
  {fontSize: responsiveScreenFontSize(0.9),  
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
  },

  blackTitle: 
  {fontSize: responsiveScreenFontSize(1.1),  
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',

  },

  textUnderline: 
  {fontSize: responsiveScreenFontSize(1.1),  
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',
    textDecorationLine: 'underline'
  },

  blackTitleLine: 
  {fontSize: responsiveScreenFontSize(1.1),    
    textAlign: 'center',  
    color: '#292929', 
    fontFamily: 'Roboto-700', 
    fontWeight: '700',

    textDecorationLine: 'underline'
  },



  });

  export default styles;
