


export const en = {   

  language: 'en',

  Suprt: 'Support',
  GraDes: 'Graphic constructor',
  GraDes1: 'Graphic',
  GraDes2: 'constructor',
  DesAny: 'Сreate your design for everything',
  DesAny1: 'Сreate your design',
  DesAny2: 'for everything',
  AppName: 'Viz&eArt',
  WhatDes: 'Create your design of Business card, Postcard, Publishing, Presentation, Certificate, Menu, Invitation, Poster, Photo collage, Flyer, Frame, Adv',
  Social: 'Social',
  Share: 'Share with',
  Frns: 'your friends',
  EaseM: 'Easy to add',
  DifEl: 'different elements',
  CustEl: 'and customize it',
  IfLike: 'if you like',
  Contact1: 'Contact',
  Contact2: 'us',
  WeLike: 'We like',
  ToHear: 'to hear from you',
  Thanks: 'Thank you for your visit!',

  TeamMn: 'Apps Maker',
  PrtMn: 'Portfolio',
  CntsMn: 'Contacts',
  DescMn: 'Development and coding',
  DesMn2: 'Mobile applications,',
  DesMn3: 'AI integration',
  DesMn4: 'Technical support',
  DesMn5: "Create a design for your app and we will bring your design to life. We implement any functionality and AI for your app or website",
  VisCnst: 'Visual Constructor',

  
Prjs1: 'Our',
Prjs2: 'projects',
OthToo: 'These and others',
AnyFoo: 'Any functionality',


Wrus1: 'Write',
Wrus2: 'to us',
WeGl1: 'We will be glad',
WeGl2: 'to help you',
ThVis: 'Thank you for your visit!',

MainTxt1: "     The application allows you to create an interactive interface of your unique application or reproduce successful other applications. The application has the ability to reproduce all modern application interface elements using only the selection of options and gestures. You can also easily create interactive elements and set your own logic for their work and then test your app in full screen mode.",
MainTxt2: "     The created interactive layout and the entered data are for us a complete or almost complete technical task for the development of your application! So you do not have to write a technical task or explain what you want - everything will be in the layout. This will save a huge amount of time for our specialists on approval and development of your application, as a result, the price for creating your application will be several times cheaper than when creating an application using a standard protocol, especially if your application only contains interface elements.The program will calculate how much your application will cost and you will be pleasantly surprised by the result! ",
MainTxt3: "     If you have an application with complex functionality, then our specialists will add any modern functionality to your project. We have graphic designers and electronic engineers on staff for ambitious projects.",
MainTxt4: "     For detailed instructions on how to create applications, see our YouTube channel",

}


 export const ru = {  

  language: 'ru',

  MainTxt1: "     Приложение позволяет создать интерактивный интерфейс своего уникального приложения или воспроизвести удачные другие приложения. В приложении есть возможность воспроизводить все современные элементы интерфейсов приложений используя только выбор опций и жесты. Так же вы легко можете создать интерактивные элементы и задать свою логику для их работы и потом потестировать ваше приложение полноэкраном режиме.",
  MainTxt2: "     Созданный интерактивный макет и введенные данные являются для нас полным или почти полным техническим заданием к разработке вашего приложения! Так что вам не придется писать техническое задание или объяснять что вы хотите - все будет в макете. Это сэкономит огромное количество времени наших специалистов на согласование и разработку вашего приложения, в результате этого цена за создание вашего приложения будет в разы дешевле чем при создании приложения по стандартному протоколу, особенно если ваше приложение только содержит интерфейсные элементы. Программа сама посчитает сколько ваше приложение будет стоить и вы будете приятно удивлены итогом!",
  MainTxt3: "     Если у вас приложение со сложной функциональностью, то наши специалисты добавят в ваш проект любую современную функциональность. В штате есть графические дизайнеры и электронщики для амбициозных проектов.",
  MainTxt4: "     Подробные инструкции как создавать приложения смотрите на нашем YouTube канале",
  

  TeamMn: 'Apps Maker',
  PrtMn: 'Портфолио',
  CntsMn: 'Контакты',
  DescMn: 'Разработка и написание',
  DesMn2: 'Мобильные приложения,',
  DesMn3: 'ИИ интеграция',
  DesMn4: 'Тех поддержка',
  DesMn5: "Создайте дизайн для вашего приложения, а мы воплотим его в жизнь. Мы реализуем любой функционал и ИИ для вашего приложения или сайта",
VisCnst: 'Визуальный конструктор',

Prjs1: 'Наши',
Prjs2: 'проекты',
OthToo: 'Эти и другие',
AnyFoo: 'Любой функционал',

  //  { i18n.t('ThVis') }

  Wrus1: 'Напишите',
Wrus2: 'нам',
WeGl1: 'Будем рады',
WeGl2: 'вам помочь',
ThVis: 'Спасибо за визит!',



  Suprt: 'Поддержка',
  GraDes: 'Графический конструктор',
  GraDes1: 'Графический',
  GraDes2: 'конструктор',
  DesAny: 'Создай свой дизайн для всего',
  DesAny1: 'Создай свой дизайн',
  DesAny2: 'для всего',
  AppName: 'Viz&eArt',
  WhatDes: 'Создайте свой дизайн визитной карточки, открытки, публикации, презентации, сертификата, меню, приглашения, плаката, фотоколлажа, флаера, рамки, рекламы',
  Social: 'Сообщество',
  Share: 'Делитесь со',
  Frns: 'своими друзьями',
  EaseM: 'Легко добавить',
  DifEl: 'разные элементы',
  CustEl: 'и настроить их',
  IfLike: 'если хотите',
  Contact1: 'Наши',
  Contact2: 'контакты',
  WeLike: 'Спрашивайте,',
  ToHear: 'это важно для нас',
  Thanks: 'Спасибо за визит!',
};   



export const sp = {  

  language: 'sp',

  MainTxt1: "     La aplicación le permite crear una interfaz interactiva de su aplicación única o reproducir otras aplicaciones exitosas. La aplicación tiene la capacidad de reproducir todos los elementos de la interfaz de la aplicación moderna utilizando solo la selección de opciones y gestos. También puede crear fácilmente elementos interactivos y establezca su propia lógica para su trabajo y luego pruebe su aplicación en modo de pantalla completa.",
MainTxt2: "     ¡El diseño interactivo creado y los datos ingresados ​​son para nosotros una tarea técnica completa o casi completa para el desarrollo de su aplicación! Por lo tanto, no tiene que escribir una tarea técnica o explicar lo que quiere: todo estará en el diseño Esto ahorrará una gran cantidad de tiempo a nuestros especialistas en la aprobación y el desarrollo de su aplicación, como resultado, el precio de crear su aplicación será varias veces más económico que al crear una aplicación usando un protocolo estándar, especialmente si su aplicación solo contiene elementos de la interfaz. ¡El programa calculará cuánto costará su aplicación y se sorprenderá gratamente con el resultado!",
MainTxt3: "     Si tiene una aplicación con una funcionalidad compleja, nuestros especialistas agregarán cualquier funcionalidad moderna a su proyecto. Contamos con diseñadores gráficos e ingenieros electrónicos en el personal para proyectos ambiciosos.",
MainTxt4: "     Para obtener instrucciones detalladas sobre cómo crear aplicaciones, consulte nuestro canal de YouTube.",

  Wrus1: 'Escríbenos',
Wrus2: '',
WeGl1: 'Estaremos encantados',
WeGl2: 'de ayudarle',
ThVis: '¡Gracias por su visita!',

  Prjs1: 'Nuestros',
Prjs2: 'proyectos',
OthToo: 'Estos y otros',
AnyFoo: 'Cualquier funcionalidad',

  TeamMn: 'Apps Maker',
   PrtMn: 'Portafolio',
   CntsMn: 'Contactos',
   DescMn: 'Diseñar y escribir',
   DesMn2: 'Aplicaciones móviles,',
   DesMn3: 'integración de IA',
   DesMn4: 'Soporte técnico',
   DesMn5: "Crea un diseño para tu aplicación y le daremos vida a tu diseño. Implementamos cualquier funcionalidad e IA para tu aplicación o sitio web",
   VisCnst: 'Constructor visual',

  Suprt: 'Soporte',
  GraDes: 'Constructor gráfico',
  GraDes1: 'Constructor',
  GraDes2: 'gráfico',
   DesAny: 'Cree su diseño para todo',
   DesAny1: 'Cree su diseño',
   DesAny2: 'para todo',
   AppName: 'Viz&eArt',
   WhatDes: 'Cree su diseño de tarjeta de visita, postal, publicación, presentación, certificado, menú, invitación, póster, collage de fotos, volante, marco, anuncio',
   Social: 'Sociales',
   Share: 'Compartir con',
   Frns: 'tus amigos',
   EaseM: 'Fácil de agregar',
   DifEl: 'diferentes elementos',
   CustEl: 'y personalizarlo',
   IfLike: 'si te gusta',
   Contact1: 'Contacto',
   Contact2: 'nosotros',
   WeLike: 'Nos gusta',
   ToHear: 'para saber de ti',
   Thanks: '¡Gracias por su visita!',

   };