import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Linking, Text, View, Image, Dimensions, Platform, TouchableOpacity, ScrollView } from 'react-native';
import styles from './styles';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,

  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveScreenFontSize
} from "react-native-responsive-dimensions";

//const { width, height } = Dimensions.get('window');  

// const width = 2701;
// const height = 1336;

const height = responsiveScreenHeight(100);
const width = responsiveScreenWidth(100);


const wPhone = width > 900 ? height*0.55*0.498 : width*0.55*0.498;

export default class GTS extends React.Component {

  state = {
    selected: null
  };

componentDidMount(){


}
  async scrollToItem(itemIndex, activeCategor ) {

 
  this.setState({selected: activeCategor}, () => this._scrollView.scrollTo({y: itemIndex * (height*0.9 + 0)})); 
 
  //this.updateMe();
       };

       async scrollToItemMobil(itemIndex, activeCategor ) {

 
        this.setState({selected: activeCategor}, () => this._scrollView.scrollTo({y: itemIndex * (width + 0)})); 
       
        //this.updateMe();
             };

  render() {


if(width > height && width > 900){

    return (
      <View style={styles.container}>
  
         {/* <ScrollView
    horizontal={true}
    > */}
  
  <View style={{width, height: height*0.15, flexDirection: 'row', marginBottom: 80,
    alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: width*0.05,  backgroundColor: '#EFE7E7', }}>
    <Text style = {styles.white150w700Left}>GTS Global Transport Sharing</Text>
  
    <View style={{width: width*0.31, height: height*0.15, flexDirection: 'row', paddingHorizontal: 50,
    alignItems: 'center', justifyContent: 'flex-end', 
     }}>
        {/* <TouchableOpacity  
                onPress={() => { 
                  this.scrollToItem(1, 'team')
                 // this.setState({selected: 'team'});
                  }}
        >
          {
          this.state.selected == 'team' ?
    <Text style = {styles.blackTitleLine}>Passenger</Text>
    :
    <Text style = {styles.blackTitle}>Passenger</Text>
          }
    </TouchableOpacity>   */}
  
    {/* <TouchableOpacity  
                onPress={() => { 
                  this.scrollToItem(2, 'apps')
                //  this.setState({selected: 'apps'});
                  }}
        >
          {
          this.state.selected == 'apps' ?
    <Text style = {styles.blackTitleLine}>Driver</Text>
    :
    <Text style = {styles.blackTitle}>Driver</Text>
          }
    </TouchableOpacity>   */}
  
    <TouchableOpacity  
                onPress={() => { 
                  this.scrollToItem(5, 'contacts')
                 // this.setState({selected: 'contacts'});
                  }}
        >
          {
          this.state.selected == 'contacts' ?
    <Text style = {styles.blackTitleLine}>Contacts</Text>
    :
    <Text style = {styles.blackTitle}>Contacts</Text>
          }
    </TouchableOpacity>   
  
  
    </View>
  
    <Image
             source={require("./assets/GTS_round.png")}    
            style={{ position: 'absolute',  right: width*0.05,   height: height*0.2, width: height*0.2 }} 
          />
    </View>
  
  
        <ScrollView
         ref={view => this._scrollView = view}
        showsVerticalScrollIndicator = {false}>
  
  
  <View style={{width: width*0.9, height: height*0.9,
  flexDirection: 'row' }}>
  
  <View style={{width: width*0.45, height: height*0.9, alignItems: 'flex-start', justifyContent: 'center', paddingLeft: 100, backgroundColor: 'white'}}>
  
  
  <Text style = {styles.black40w400}>Free ridesharing app</Text>
    
  
    <View style = {{paddingTop: 15, paddingBottom: 15, width: width*0.3}}>
  <Text style = {styles.red80w700}>
  GTS passenger
    </Text>
    </View>
  
    <Text style = {styles.black40w400}>
    You have the right to choose!
    </Text>
  
    <View style = {{paddingTop: 80, paddingBottom: 40, width: width*0.3}}>
    <Text style = {styles.black20w400}>
    You can choose what car and driver you want, how much to pay and no ridesharing companies' fees or cancellation fee or surge ever!
    
    </Text>
    </View>
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
  
  </View>
  
  
  
  <View style={{width: width*0.4, height: height*0.9, backgroundColor: '#FF5A00'}}>
  
  <Image
             source={require("./assets/4e.png")}    
            style={{ position: 'absolute', top: height*0.15, left: -height*0.1,  height: height*0.6, width: height*0.6*0.498 }} 
          />
  
  <Image
             source={require("./assets/00e.png")}    
            style={{ position: 'absolute', top: -height*0.2, left: wPhone ,  height: height*0.6, width: height*0.6*0.498 }} 
          />
  
  <Image
             source={require("./assets/6e.png")}    
            style={{ position: 'absolute', top: height*0.4 + 20, left: wPhone ,  height: height*0.6, width: height*0.6*0.498 }} 
          />
  
  </View>
  
  <View style={{width: width*0.05, height: height*0.9, backgroundColor: '#ffffff'}}></View>
  
  </View>
  
  
  <View style={{width: width*0.9, height: height*0.9, flexDirection: 'row', alignContent: 'center',  backgroundColor: 'transparent'}}>
  
  <View style = {{ position: 'absolute', top: 300, right: 30 , }}>
  <View style = {{paddingTop: 90,  width: width*0.3}}> 
  <Text style = {styles.black80w700}>Sign in</Text>

  <View style = {{paddingTop: 40, paddingBottom: 40,  width: width*0.3}}> 
  <Text style = {styles.black40w400}>
    Easy authorisation</Text>
    <Text style = {styles.black40w400}>by phone
    </Text>
    </View>

    </View>
  
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
    </View>
  
    <Image
             source={require("./assets/00e.png")}    
            style={{ position: 'absolute', top: height*0.08, left: height*0.1,  height: height*0.7, width: height*0.7*0.498 }} 
          />
  
  <Image
             source={require("./assets/01e.png")}    
            style={{ position: 'absolute', top: height*0.12, left: wPhone*2 ,  height: height*0.7, width: height*0.7*0.498 }} 
          />

  
  </View> 
  
  
  
  <View style={{width: width*0.9, height: height*0.9,  backgroundColor: '#FF5A00'}}>
  
  <View style = {{ position: 'absolute', top: 50, left: 100 , }}>
  <View style = {{paddingTop: 40,  width: width*0.3}}> 
  <Text style = {styles.white80w700}>Easy to make</Text>
    <Text style = {styles.white80w700}>a custom request</Text>
    </View>
  
    <View style = {{paddingTop: 40, paddingBottom: 40, width: width*0.25}}>
    
    <Text style = {styles.white20w400}>
    with info and </Text>
    <Text style = {styles.white20w400}>picture if you like
    </Text>
    </View> 
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#ffffff'}}></View>
    </View>
 
    <Image
             source={require("./assets/2e.png")}    
            style={{ position: 'absolute', top: height*0.08, right: height*0.1,  height: height*0.7, width: height*0.7*0.498 }} 
          />
  
  <Image
             source={require("./assets/3e.png")}    
            style={{ position: 'absolute', top: height*0.12, right: wPhone*2 ,  height: height*0.7, width: height*0.7*0.498 }} 
          />

    </View>




  
    <View style={{width: width*0.9, height: height*0.9, flexDirection: 'row', alignContent: 'center',  backgroundColor: 'transparent'}}>
  
  <View style = {{ position: 'absolute', top: 300, right: 30 , }}>
  <View style = {{paddingTop: 90,  width: width*0.3}}> 
  <Text style = {styles.black80w700}>Select offers</Text>

  <View style = {{paddingTop: 40, paddingBottom: 40,  width: width*0.3}}> 
  <Text style = {styles.black40w400}>
  by car make, year, price</Text>
    <Text style = {styles.black40w400}>or time to pickup
    </Text>
    </View>

    </View>
  
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
    </View>
  
    <Image
             source={require("./assets/4e.png")}    
            style={{ position: 'absolute', top: height*0.08, left: height*0.1,  height: height*0.7, width: height*0.7*0.498 }} 
          />
  
  <Image
             source={require("./assets/5e.png")}    
            style={{ position: 'absolute', top: height*0.12, left: wPhone*2 ,  height: height*0.7, width: height*0.7*0.498 }} 
          />

  
  </View> 




  
  <View style={{width: width*0.9, height: height*0.9,  backgroundColor: '#FF5A00'}}>
  
  <View style = {{ position: 'absolute', top: 50, left: 100 , }}>
  <View style = {{paddingTop: 40,  width: width*0.3}}> 
  <Text style = {styles.white80w700}>Write reviews</Text>
    </View>
  
    <View style = {{paddingTop: 40, paddingBottom: 40, width: width*0.25}}>
    
    <Text style = {styles.white20w400}>
    to make this app</Text>
    <Text style = {styles.white20w400}>better and better
    </Text>
    </View> 
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#ffffff'}}></View>
    </View>
 
    <Image
             source={require("./assets/6e.png")}    
            style={{ position: 'absolute', top: height*0.055, right: height*0.3,  height: height*0.8, width: height*0.8*0.498 }} 
          />


    </View>



  
    <View style={{width: width*0.9, height: height*0.9, flexDirection: 'row',  backgroundColor: 'transparent'}}>
  
  
  <View style = {{paddingTop: 80,  width: width*0.3, paddingLeft: 100}}> 
  <Text style = {styles.black80w700}>Contact</Text>
  <Text style = {styles.black80w700}>us</Text>
  
    <View style = {{paddingTop: 40, paddingBottom: 40, width: width*0.3}}>
    
    <Text style = {styles.black40w400}>We like</Text>
    <Text style = {styles.black40w400}>to hear from you</Text>
    </View> 
    
  
    <View style={{width: width*0.1, height: height*0.01, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
    
    </View>
  
    <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start', paddingTop: height*0.05}}>
    <Image
             source={require("./assets/contacts.png")}    
            style={{ 
            width: width*0.38, height: width*0.4, borderRadius: 20, }} 
          />
  
  </View>
  
    </View>
  
    <View style={{width: width*0.42, height: width*0.1, alignSelf: 'center', flexDirection: 'row',
     justifyContent: 'space-between', alignItems: 'center',  backgroundColor: 'transparent'}}>

<TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://apps.apple.com/app/id1589467548`);
            }}
        >
    <Image
             source={require("./assets/appStore.png")}    
            style={{width: width*0.2, height: width*0.062,  }} 
          />
</TouchableOpacity>

<TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://play.google.com/store/apps/details?id=com.angelEngineering.gts`);
            }}
        >
    <Image
             source={require("./assets/googlePlay.png")}    
             style={{width: width*0.2, height: width*0.062,  }} 
          />
</TouchableOpacity>


    </View>
  
    <View style={{width: width*0.9, height: height*0.15, 
    alignItems: 'center', justifyContent: 'center',  backgroundColor: '#FF5A00'}}>
  
  <Text style = {styles.white150w700}>Thank you for your visit!</Text>
  
    </View>
  
    <View style={{width: width*0.9, height: height*0.1, 
    alignItems: 'center', justifyContent: 'center',  backgroundColor: 'transparent'}}>
    <Text style = {styles.black20w400}>© 2021 Angel Engineering</Text>
    </View>
  
  </ScrollView> 
      </View>
    );

 
}




else{
  return (
    <View style={styles.containerMob}>

       {/* <ScrollView
  horizontal={true}
  > */}

<View style={{width, height: width*0.18, flexDirection: 'row', 
  alignItems: 'center', justifyContent: 'flex-start', paddingHorizontal: width*0.05,  backgroundColor: '#EFE7E7', 
  marginBottom: width > 900 ? 80 : 0,
  }}>

    <View style={{width: width*0.7, height: width*0.18, alignItems: 'flex-start', justifyContent: 'center', }}>
  <Text style = {styles.white150w700Left}>GTS Global Transport Sharing</Text>
  </View>
{
  width < 900 ?
  null
  :
  <View style={{width: width*0.385, height: width*0.18, flexDirection: 'row', paddingHorizontal: width*0.05,
  alignItems: 'center', justifyContent: 'space-around', 
   }}>
      {/* <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(1, 'team')
               // this.setState({selected: 'team'});
                }}
      >
        {
        this.state.selected == 'team' ?
  <Text style = {styles.blackTitleLine}>Команда</Text>
  :
  <Text style = {styles.blackTitle}>Команда</Text>
        }
  </TouchableOpacity>  

  <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(2, 'apps')
              //  this.setState({selected: 'apps'});
                }}
      >
        {
        this.state.selected == 'apps' ?
  <Text style = {styles.blackTitleLine}>Портфолио</Text>
  :
  <Text style = {styles.blackTitle}>Портфолио</Text>
        }
  </TouchableOpacity>   */}

  {/* <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(5, 'contacts')
               // this.setState({selected: 'contacts'});
                }}
      >
        {
        this.state.selected == 'contacts' ?
  <Text style = {styles.blackTitleLine}>Contacts</Text>
  :
  <Text style = {styles.blackTitle}>Contacts</Text>
        }
  </TouchableOpacity>   */}


  </View>
  }

  <Image
           source={require("./assets/GTS_round.png")}    
          style={{ position: 'absolute',  right: width*0.05,   height: width*0.2, width: width*0.2 }} 
        />


  </View>


{
  width >= 900 ?
  null
  :
  <View style={{width: width*0.7, height: width*0.1, flexDirection: 'row', paddingHorizontal: width*0.05, 
  alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'space-around', marginBottom: 10,
   }}>
      {/* <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(1, 'team')
               // this.setState({selected: 'team'});
                }}
      >
        {
        this.state.selected == 'team' ?
  <Text style = {styles.blackTitleLine}>Команда</Text>
  :
  <Text style = {styles.blackTitle}>Команда</Text>
        }
  </TouchableOpacity>  

  <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(2, 'apps')
              //  this.setState({selected: 'apps'});
                }}
      >
        {
        this.state.selected == 'apps' ?
  <Text style = {styles.blackTitleLine}>Портфолио</Text>
  :
  <Text style = {styles.blackTitle}>Портфолио</Text>
        }
  </TouchableOpacity>   */}

  {/* <TouchableOpacity  
              onPress={() => { 
                this.scrollToItemMobil(3, 'contacts')
               // this.setState({selected: 'contacts'});
                }}
      >
        {
        this.state.selected == 'contacts' ?
  <Text style = {styles.blackTitleLine}>Contacts</Text>
  :
  <Text style = {styles.blackTitle}>Contacts</Text>
        }
  </TouchableOpacity>   */}


  </View>
  }


      <ScrollView
       ref={view => this._scrollView = view}
      showsVerticalScrollIndicator = {false}>


<View style={{width: width*0.9, height: width*1,
flexDirection: 'row' }}>

<View style={{width:  width*0.45 , height: width*1,  alignItems: 'flex-start', 
justifyContent: 'center', paddingLeft: width > 900 ? 100 : 10, }}>

<View style = {{width: width*0.4}}>
<Text style = {styles.black40w400}>Free ridesharing app</Text>
  </View>

  <View style = {{paddingTop: 15, paddingBottom: 15, width: width*0.4}}>
<Text style = {styles.red80w700}>GTS</Text>
<Text style = {styles.red80w700}>passenger</Text>
  </View>

  <View style = {{width: width*0.4}}>
  <Text style = {styles.black40w400}>You have the right</Text>
  <Text style = {styles.black40w400}>to choose!

  </Text>
  </View>

  <View style = {{paddingTop: width > 900 ? 80 : 40, paddingBottom: width > 900 ? 40 : 20,  width: width*0.3}}>
  <Text style = {styles.black20w400}>
  You can choose what car and driver you want, how much to pay and no ridesharing companies' fees or cancellation fee or surge ever!
  
  </Text>
  </View> 

  <View style={{width: width*0.1, height: width*0.005, borderRadius: width*0.005/2,  backgroundColor: '#292929'}}></View> 

</View>



<View style={{width: width > 900 ? width*0.4 : width*0.5, height: width*1, backgroundColor: '#FF5A00'}}>

<Image
           source={require("./assets/4e.png")}    
          style={{ position: 'absolute', top: width*0.2, left: -width*0.07,  height: width*0.6, width: width*0.6*0.498 }} 
        />

<Image
           source={require("./assets/00e.png")}    
          style={{ position: 'absolute', top:  width > 900 ? -width*0.2 :  -width*0.1, left: wPhone ,   height: width*0.6, width: width*0.6*0.498 }} 
        />

<Image
           source={require("./assets/6e.png")}    
          style={{ position: 'absolute', top: width > 900 ? width*0.4 + 20 : width*0.5 + 20, left: wPhone ,   height: width*0.6, width: width*0.6*0.498}} 
        />

</View>

{width < 900 ?
null
:
<View style={{width: width*0.05, height: height*0.9, backgroundColor: '#ffffff'}}></View>
  }
</View>


<View style={{width: width*0.9, height: width,  backgroundColor: 'transparent'}}>
  
  <View style = {{ position: 'absolute', top: 50, right: 0 , }}>
  <View style = {{paddingTop: 90,  width: width*0.35}}> 
  <Text style = {styles.black80w700}>Sign in</Text>

  <View style = {{paddingTop: 15, paddingBottom: 15,  width: width*0.3}}> 
  <Text style = {styles.black40w400}>
    Easy authorisation</Text>
    <Text style = {styles.black40w400}>by phone
    </Text>
    </View>

    </View>
  
  
    <View style={{width: width*0.1, height: width*0.005, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
    </View>
  
    <Image
             source={require("./assets/00e.png")}    
            style={{ position: 'absolute', top: height*0.08, left: width > 900 ? 100 : 10,  height: width*0.6, width: width*0.6*0.498}} 
          />
  
  <Image
             source={require("./assets/01e.png")}    
            style={{ position: 'absolute', top: height*0.12, left: wPhone*0.7 ,  height: width*0.6, width: width*0.6*0.498 }} 
          />

  
  </View> 



  <View style={{width: width*0.9, height: width,  backgroundColor: '#FF5A00'}}>
  
  <View style = {{ position: 'absolute', top: 10, left: width > 900 ? 100 : 20,  }}>
  <View style = {{paddingTop: 10,  width: width*0.5}}> 
  <Text style = {styles.white80w700}>Easy to make</Text>
    <Text style = {styles.white80w700}>a custom request</Text>
    </View>
  
    <View style = {{paddingTop: 15, paddingBottom: 15, width: width*0.25}}>
    
    <Text style = {styles.white20w400}>
    with info and </Text>
    <Text style = {styles.white20w400}>picture if you like
    </Text>
    </View> 
  
    <View style={{width: width*0.1, height: width*0.005, borderRadius: height*0.01/2,  backgroundColor: '#ffffff'}}></View>
    </View>
 
    <Image
             source={require("./assets/2e.png")}    
            style={{ position: 'absolute', top: height*0.08, right: 20, height: width*0.6, width: width*0.6*0.498 }} 
          />
  
  <Image
             source={require("./assets/3e.png")}    
            style={{ position: 'absolute', top: height*0.12, right: wPhone*0.9 ,  height: width*0.6, width: width*0.6*0.498  }} 
          />

    </View>




    <View style={{width: width*0.9, height: width,  backgroundColor: 'transparent'}}>
  
  <View style = {{ position: 'absolute', top: 50, right: 0 , }}>
  <View style = {{paddingTop: 90,  width: width*0.35}}> 
  <Text style = {styles.black80w700}>Select offers</Text>

  <View style = {{paddingTop: 15, paddingBottom: 15,  width: width*0.3}}> 
  <Text style = {styles.black40w400}>
  by car make, year, price</Text>
    <Text style = {styles.black40w400}>or time to pickup
    </Text>
    </View>

    </View>
  
  
    <View style={{width: width*0.1, height: width*0.005, borderRadius: height*0.01/2,  backgroundColor: '#292929'}}></View>
    </View>
  
    <Image
             source={require("./assets/4e.png")}    
            style={{ position: 'absolute', top: height*0.08, left: width > 900 ? 100 : 10,  height: width*0.6, width: width*0.6*0.498}} 
          />
  
  <Image
             source={require("./assets/5e.png")}    
            style={{ position: 'absolute', top: height*0.12, left: wPhone*0.7 ,  height: width*0.6, width: width*0.6*0.498 }} 
          />

  
  </View> 





  <View style={{width: width*0.9, height: width,  backgroundColor: '#FF5A00'}}>
  
  <View style = {{ position: 'absolute', top: 10, left: width > 900 ? 100 : 20,  }}>
  <View style = {{paddingTop: 10,  width: width*0.5}}> 
  <Text style = {styles.white80w700}>Write reviews</Text>
    </View>
  
    <View style = {{paddingTop: 15, paddingBottom: 15, width: width*0.25}}>
    
    <Text style = {styles.white20w400}>
    to make this app</Text>
    <Text style = {styles.white20w400}>better and better
    </Text>
    </View> 
  
    <View style={{width: width*0.1, height: width*0.005, borderRadius: height*0.01/2,  backgroundColor: '#ffffff'}}></View>
    </View>
 
    <Image
             source={require("./assets/6e.png")}    
            style={{ position: 'absolute', top: height*0.05, right: 30, height: width*0.75, width: width*0.75*0.498 }} 
          />


    </View>

  <View style={{width: width*0.9, height: width, flexDirection: 'row',  backgroundColor: 'transparent'}}>


<View style = {{paddingTop: 20,  width: width*0.4, paddingLeft: width > 900 ? 100 : 10,}}> 
<Text style = {styles.black80w700}>Contact</Text>
<Text style = {styles.black80w700}>us</Text>

  <View style = {{paddingTop: 15, paddingBottom: width > 900 ? 40 : 20, width: width*0.3}}>
  
  <Text style = {styles.black40w400}>We like</Text>
  <Text style = {styles.black40w400}>to hear from you</Text>
  </View> 
  

  <View style={{width: width*0.1, height: width*0.005, borderRadius: width*0.005/2,  backgroundColor: '#292929'}}></View> 
  
  </View>

  <View style = {{width: width*0.3,  alignItems: 'center', justifyContent: 'flex-start',
  marginLeft: 10, paddingTop: width*0.05}}>
  <Image
           source={require("./assets/contacts.png")}    
          style={{ 
          width: width*0.38, height: width*0.4, borderRadius: 20, }} 
        />

</View>

  </View>

  <View style={{width: width*0.65, height: width*0.14, alignSelf: 'center', flexDirection: 'row',
     justifyContent: 'space-between', alignItems: 'center',  backgroundColor: 'transparent'}}>

<TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://apps.apple.com/app/id1589467548`);
            }}
        >
    <Image
             source={require("./assets/appStore.png")}    
            style={{width: width*0.3, height: width*0.093,  }} 
          />
</TouchableOpacity> 

<TouchableOpacity  
          onPress={ () => {
            Linking.openURL(`https://play.google.com/store/apps/details?id=com.angelEngineering.gts`);
            }}
        >
    <Image
             source={require("./assets/googlePlay.png")}    
             style={{width: width*0.3, height: width*0.093,  }} 
          />
</TouchableOpacity>


    </View>

  <View style={{width: width*0.9, height: width*0.15, 
  alignItems: 'center', justifyContent: 'center',  backgroundColor: '#FF5A00'}}>

<Text style = {styles.white150w700}>Thank you for your visit!</Text>

  </View>

  <View style={{width: width*0.9, height: width*0.1, 
  alignItems: 'center', justifyContent: 'center',  backgroundColor: 'transparent'}}>
  <Text style = {styles.black20w400}>© 2021 Angel Engineering</Text>
  </View>

</ScrollView> 
    </View>
  );
}





}
}

