import React from 'react';
import { Text, View, Image, Dimensions, TouchableOpacity, ScrollView, WebView} from 'react-native';
import styles from './styles'; 

import { MaterialCommunityIcons } from '@expo/vector-icons';

const { width, height } = Dimensions.get('window');  


var   TextAgreemRu = 'Вы признаете и соглашаетесь с тем, что в максимальной степени, разрешенной применимым законодательством, приложение и любые услуги, выполняемые или предоставляемые приложением, предоставляются «как есть» и «по мере доступности» со всеми ошибками.';
var   TextAgreemEs = 'Usted reconoce y acepta expresamente que, en la medida máxima permitida por la ley aplicable, la aplicación y los servicios realizados o proporcionados por la aplicación se proporcionan "tal cual" y "según disponibilidad" con todas las fallas.';
var   TextAgreemEn = 'You expressly acknowledge and agree that to the maximum extent permitted by applicable law, the application and any services performed or provided by the application are provided "as is" and "as available" with all faults.';
   

export default class agrmAppsMaker extends React.Component {

  
  state = {
    chooseLng: 'En',
  };

    render() {
    return (
      <View style={styles.container}>

<TouchableOpacity
        style = {{width: width*0.15, height: width*0.15,
         // backgroundColor: 'red',
          marginTop: 40, alignSelf: 'center',
        alignItems: 'center', justifyContent: 'center'
        }}
        onPress={() => this.props.navigation.navigate('MainScr')}
>
<MaterialCommunityIcons name="home-circle-outline" size={50} color="black" />
</TouchableOpacity>


        <ScrollView>
 
          <Image
             source={require("./assets/IconWeb.png")}    
            style={{
              width: height*0.3, height: height*0.3, 
              marginBottom:80, marginTop: 10, 
              borderRadius: 18,
              
              alignSelf: 'center'}} 
          />
        


<View style = {{flexDirection: width < 700 ? 'column' : 'row',
width: width*0.7, alignItems: 'center', justifyContent: 'center', paddingBottom: 20, alignSelf: 'center'}}>
       

        <TouchableOpacity
        style = {{width: width*0.15, marginTop: 15, alignItems: 'center'}}
  onPress={() => this.setState({chooseLng: 'En'})}
>
  { this.state.chooseLng == 'En'?
<Text style = {styles.textUnderline}>English</Text>
:
<Text style = {styles.blackTitle}>English</Text>
}
  </TouchableOpacity>

  <TouchableOpacity
        style = {{width: width*0.15, marginTop: 15, alignItems: 'center'}}
  onPress={() => this.setState({chooseLng: 'Ru'})}
>
{ this.state.chooseLng == 'Ru'?
<Text style = {styles.textUnderline}>Русский</Text>
:
<Text style = {styles.blackTitle}>Русский</Text>
}
  </TouchableOpacity>

  <TouchableOpacity
        style = {{width: width*0.15, marginTop: 15, alignItems: 'center'}}
  onPress={() => this.setState({chooseLng: 'Es'})}
>
{ this.state.chooseLng == 'Es'?
<Text style = {styles.textUnderline}>Español</Text>
:
<Text style = {styles.blackTitle}>Español</Text>
}
  </TouchableOpacity>

  </View>



  <View style = {{width: width*0.8,   alignSelf: 'center', alignItems: 'center'}}>
  {
  this.state.chooseLng == 'En' ? 
        <Text style = {styles.black20w400}>{TextAgreemEn}</Text>
        :
        this.state.chooseLng == 'Ru' ? 
        <Text style = {styles.black20w400}>{TextAgreemRu}</Text>
        :
        <Text style = {styles.black20w400}>{TextAgreemEs}</Text>
  }
        </View>
        <View style = {{height: 50}}></View>
        </ScrollView>
      </View>
    );
  }
  }

  
